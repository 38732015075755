import { api } from "./api";
  
export const addAuthentication = async (data, value) => {
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'

    const response = await api.post("instance/addAuthentication", {
        phoneNumberId: data.phoneNumberId,
        accessToken: data.accessToken,
        waBusinessId: data.waBusinessId, 
    });
    return response;
  };
 

  export const editAuthentication = async (data, value) => {
    api.defaults.headers.common['Authorization'] = value.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
  
    const response = await api.post("instance/editAuthentication", {
        id:data.id,
        phoneNumberId: data.phoneNumberId,
        accessToken: data.accessToken,
        waBusinessId: data.waBusinessId, 
    } );
    return response;
  };
 
  
  export const viewAuthentication = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'   
    const response = await api.post("instance/viewAuthentication", {
        userId: data.userId, 
    });
    return response;
  }; 

  export const uploadFile = async (data, values) => { 
    api.defaults.headers.common['Authorization'] = values.token;
    api.defaults.headers.common['Content-Type']= 'multipart/form-data'   
     const response = await api.post("instance/uploadFile", data); 
    
    return response;
  };
 
  export const deleteInstance = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
  
    const response = await api.post("instance/deleteInstance", {
        id:data.id, 
    });
    return response;
  };
 

  export const restartInstance = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
   
    const response = await api.post("instance/restartInstance", {
        id:data.id, 
    } );
    return response;
  };
 

  
  export const resetInstance = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
  
    const response = await api.post("instance/resetInstance", {
        id:data.id, 
    });
    return response;
  };

  export const isInitiated = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
  
    const response = await api.post("instance/isInitiated", {
      mobile:data.mobile, 
    });
    return response;
  };
 

  export const getUserInstance = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
  
    const response = await api.post("instance/getUserInstance", {
      instance_id: data.instance_id
     });
    return response;
  };

  