import React, { useState, useEffect, useReducer } from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Button,
  List,
  Box,
  SwipeableDrawer, 
  TextField,
  FormControl,
  InputLabel, 
  Typography,
  Dialog, DialogContent, DialogContentText, DialogActions,
  Backdrop,
  CircularProgress,
  TablePagination
} from "@material-ui/core";
 import PageTitle from "../../components/PageTitle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import { toast } from "react-toastify";
  import ViewIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Tooltip from "@material-ui/core/Tooltip"; 
 import EditIcon from "@material-ui/icons/Edit";
import moment from "moment"; 
import useStyles from "../../themes/style.js";
 import useMediaQuery from '@material-ui/core/useMediaQuery';
 import Notification from "../../components/Notification/Notification";
 import GroupContact from "./GroupContact"; 
import { groupSchema, groupContactSchema } from "../../components/Yup/Yup";
 import { addGroup, editGroup, viewGroup, viewAllGroup, deleteGroups } from '../../services/group.api';
import { addGroupContact, removeGroupContact } from '../../services/groupContact.api';
 
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useFormik } from 'formik';
import "react-toastify/dist/ReactToastify.css"; 

export default function Group(props) {
  const classes = useStyles();
 
  const mobileQuery = useMediaQuery('(max-width:600px)');  
 
   const [groupData, setGroupData] = useState([]);

  const [Id, setId] = useState({
    group_id: "",
    contact_id: "", 
  });  

  const [groupView, setGroupView] = useState({
    data:{}
   });

  const [page, setPage] = useState(0);
  const [currerntPage, setCurrerntPage] = useState(1);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [dataList, setDataList] = useState("ADD"); 
  // const [contactData, setContactData] = useState([]);

  const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(false);
 
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress, 
          className: classes.notification,
        },
    );
  }

 
  const [uploadOpen, setUploadOpen] = React.useState(false);
  
  const handleUploadOpen = () => {
    setUploadOpen(true);
  };

  const handleUploadClose = () => {
    setUploadOpen(false);
  };

  function removeGroupContactData(data){ 
    setLoader(true);
   removeGroupContact({group_id: data.group_id, contact_id: data.contact_id, token: token}).then((response) => {    
     if (response.data.status === true) {  
       handleNotificationCall("success", response.data.message);  
       
       handleRemoveClose();

       viewGroup({id: data.group_id, token: token}).then((response) => {    
        if (response.data.status === true) {   
          setGroupView({
            ...groupView,
            data: response.data.data
          });   
          setLoader(false);
            } 
          }) 
            .catch(function (error) {
             console.log(error);
           })

     } else {
       handleNotificationCall("error", response.data.message);  
     }
    
     setLoader(false);
   }) 
   .catch(function (error) {
    console.log(error);
  })

  }
  



  const [removeOpen, setRemoveOpen] = React.useState(false);
  const [removeBulkOpen, setRemoveBulkOpen] = React.useState(false);

  const handleRemoveOpen = () => {
    setRemoveOpen(true);
  };

  const handleRemoveClose = () => {
    setRemoveOpen(false);
  };

  const handleBulkRemoveOpen = () => {
    setRemoveBulkOpen(true);
  };

  const handleBulkRemoveClose = () => {
    setRemoveBulkOpen(false);
  };
 
  
  const token = localStorage.getItem("token");

 const HeaderElements = () => <>Total : {count}</>;

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    setPage(newPage);
    setCurrerntPage(newPage + 1);

    viewAllGroup({page: newPage + 1, token:token}).then((response) => {    
      if (response.data.status === true) { 
        
        setGroupData(response?.data?.data?.group_data);
        setCount(response?.data?.data?.total_count);
           setLoader(false);

      } 
     
      setLoader(false);
    }) 
    .catch(function (error) {
     console.log(error);
   })

  };
  
  
  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
       
      viewAllGroup({page: 1, token:token}).then((response) => {    
         if (response.data.status === true) { 
           
          setGroupData(response?.data?.data?.group_data);
            setCount(response?.data?.data?.total_count);
            setLoader(false);
  
         } 
        
         setLoader(false);
       }) 
       .catch(function (error) {
        console.log(error);
      })
 
    };
    fetchData();
       
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reducerValue]);
  

   
 
  var selectId = [];

  const handleRowSelectionChange = (allRowsSelected) => {
    const id = allRowsSelected.map((rowIndex) => groupData[rowIndex.dataIndex].id);
    selectId = id;  
   };

   const [selectedId, setselectedId] = React.useState(selectId);

   function removeGroup(){

    deleteGroups({groups: selectedId, token:token}).then((response) => {    
      if (response.data.status === true) {    
        forceUpdate();
         handleNotificationCall("success", response.data.message);  
        
         setLoader(false);
         handleBulkRemoveClose();
        
         
          } else{
            handleNotificationCall("error", response.data.message);  
          }
        })
          .catch(function (error) {
           console.log(error);
         })
  }


  const SelectElements = () => <Grid className={classes.gap10}>  
 
 <Button
  variant="contained"
  color="secondary"
  size="small"
  startIcon={<DeleteForeverIcon />}
  onClick={() =>{
   setselectedId(selectId);
   handleBulkRemoveOpen();
  
  }}
 >
 Remove Group
 </Button>
 </Grid>;

// function getViewAllContact(id){

//   viewAllContact({id}).then((response) => {    
//     if (response.data.status === true) {  
//      setContactData(response.data.data); 
//     }  
//   }) 
//   .catch(function (error) {
//    console.log(error);
//  })
// } 


   const onGroupAdd = useFormik({
    initialValues: { 
      name: '',
      description: '', 
    },
     validationSchema: groupSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
      setLoader(true);
      addGroup(values, {token:token}).then((response) => {    
      if (response.data.status === true) { 
        handleNotificationCall("success", response.data.message);  
        forceUpdate(); 
        resetForm();
        setState({ ...state, right: false }); 
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })

    },
  });  

  const onGroupEdit = useFormik({
    initialValues: {  
      id:"",
      name:  "",
      description:  "", 
    },
     validationSchema: groupSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
      setLoader(true);
      editGroup(values,  { id: onGroupEdit.values.id, token: token}).then((response) => {    
      if (response.data.status === true) { 
        handleNotificationCall("success", response.data.message);  
        forceUpdate(); 
        resetForm();
        setState({ ...state, right: false }); 
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })

    },

   
  });  
 

  const onGroupContactAdd = useFormik({
    initialValues: { 
      group_id: '',
      contact_id: "", 
    },
     validationSchema: groupContactSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
      setLoader(true);
      addGroupContact({ token: token, group_id: values.group_id, contacts: values.contact_id.map((value) => `${value.id}`)}).then((response) => {    
      if (response.data.status === true) { 
        handleNotificationCall("success", response.data.message);  
        forceUpdate(); 
        resetForm();
        setState({ ...state, right: false }); 
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })

    },
  });  


  function handleShow(values, name) {
    setLoader(true);
    if (name === "EDIT") {
      setDataList("EDIT");
    } else {
      setDataList("VIEW");
    } 
 
    viewGroup({id: values, token: token}).then((response) => {    
      if (response.data.status === true) {   
         setGroupView({
          ...groupView,
          data: response.data.data
        });  

        onGroupEdit.setValues(response.data.data.group_data);  
        setState({ ...state, right: true });
        setLoader(false);
          } 
        }) 
          .catch(function (error) {
           console.log(error);
         })
 
 
  }
 
  const [state, setState] = useState({ 
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {  
    setState({ ...state, [anchor]: open });
  }; 

  const list = (anchor) =>
  dataList === "ADD" ? (
    <Box sx={{ width: "100%" }} role="presentation">
    <List>
      <Card className={classes.root}>
        <CardHeader>
          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.drawerHeader}
          >
            <Typography variant="subtitle1"> Add Group</Typography>

            <Grid className={classes.drawerClose}>
              <CloseIcon
                className={classes.closeBtn}
                size="14px"
                onClick={toggleDrawer(anchor, false)}
              />
            </Grid>
          </Grid>
        </CardHeader>

       
     <form onSubmit={onGroupAdd.handleSubmit}  >

    <CardContent className={classes.drawerContent}>
        <Grid container direction="row" spacing={2}>
        <Grid item xs={12} >
            <InputLabel shrink htmlFor="name">  Name    </InputLabel>

            <FormControl className={classes.margin}>
              <TextField
                classes={{ root: classes.customTextField }}
                InputProps={{ disableUnderline: true }}
                size="small"
                placeholder="Enter name"
                type="text"
                name="name" 
                value={onGroupAdd.values.name}
                onChange={onGroupAdd.handleChange}
                error={onGroupAdd.touched.name && Boolean(onGroupAdd.errors.name)}
                helperText={onGroupAdd.touched.name && onGroupAdd.errors.name}

              />
 
            </FormControl>
          </Grid>
         

          <Grid item xs={12}>
            <InputLabel shrink htmlFor="description"> Description </InputLabel>

            <FormControl className={classes.margin}>
              <TextField
               multiline
                rows={3} 
                classes={{ root: classes.customTextField }}
                InputProps={{ disableUnderline: true }}
                size="small"
                placeholder="Enter description"
                type="text"
                name="description" 
                value={onGroupAdd.values.description}
                onChange={onGroupAdd.handleChange}
                error={onGroupAdd.touched.description && Boolean(onGroupAdd.errors.description)}
                helperText={onGroupAdd.touched.description && onGroupAdd.errors.description} 
              />

             
            </FormControl>
          </Grid>
      
     
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.drawerFooter}
        >
        

          <Button
            variant="contained"
            color="primary"
            size="small"
             type="submit"
             disabled={onGroupAdd.isSubmitting}
          >
            Save
          </Button>

          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={toggleDrawer(anchor, false)}
          >
            
            Close
          </Button>
        </Grid>
      </CardActions>
    </form>
    </Card>
        </List>
        </Box>
 
  ) :  dataList === "EDIT" ? (
    <Box sx={{ width: "100%" }} role="presentation">
    <List>
      <Card className={classes.root}>
        <CardHeader>
          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.drawerHeader}
          >
            <Typography variant="subtitle1"> Edit Group</Typography>

            <Grid className={classes.drawerClose}>
              <CloseIcon
                className={classes.closeBtn}
                size="14px"
                onClick={toggleDrawer(anchor, false)}
              />
            </Grid>
          </Grid>
        </CardHeader>

                   <form onSubmit={onGroupEdit.handleSubmit}  >
                  <CardContent className={classes.drawerContent}>
                      <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} >
                          <InputLabel shrink htmlFor="name">  Name    </InputLabel>

                          <FormControl className={classes.margin}>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              InputProps={{ disableUnderline: true }}
                              size="small"
                              placeholder="Enter name"
                              type="text"
                              name="name" 
                             
                              value={onGroupEdit.values.name}
                              onChange={onGroupEdit.handleChange}
                              error={onGroupEdit.touched.name && Boolean(onGroupEdit.errors.name)}
                              helperText={onGroupEdit.touched.name && onGroupEdit.errors.name} 
                            />

                        
                          </FormControl>
                        </Grid>
                        

                        <Grid item xs={12} >
                          <InputLabel shrink htmlFor="description"> Description  </InputLabel>

                          <FormControl className={classes.margin}>
                            <TextField
                             multiline
                          rows={5} 
                              classes={{ root: classes.customTextField }}
                              InputProps={{ disableUnderline: true }}
                              size="small"
                              placeholder="Enter description"
                              type="text"
                              name="description"
                              
                              value={onGroupEdit.values.description}
                              onChange={onGroupEdit.handleChange}
                              error={onGroupEdit.touched.description && Boolean(onGroupEdit.errors.description)}
                              helperText={onGroupEdit.touched.description && onGroupEdit.errors.description} 
                            />

                            
                          </FormControl>
                        </Grid>

                     
                        
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        className={classes.drawerFooter}
                      >
                

                        <Button
                       
                           variant="contained"
                           color="primary"
                           size="small"
                            type="submit"
                            disabled={onGroupEdit.isSubmitting}

                        >
                          Update
                        </Button>

                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          onClick={toggleDrawer(anchor, false)}
                        >
                          
                          Close
                        </Button>
                      </Grid>
                    </CardActions>
                  </form>
                  </Card>
        </List>
        </Box>
  ) : (
      <>
        <Box sx={{ width: "100%" }} role="presentation">
          <List>
            <Card className={classes.root}>
              <CardHeader>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  className={classes.drawerHeader}
                >
                  <Typography variant="subtitle1"> View Group</Typography>

                  <Grid className={classes.drawerClose}>
                    <CloseIcon
                      className={classes.closeBtn}
                      size="14px"
                      onClick={toggleDrawer(anchor, false)}
                    />
                  </Grid>
                </Grid>
              </CardHeader>

              <CardContent className={classes.drawerContent}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}>  Name  </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {groupView.data?.group_data?.name}  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>    <Typography className={classes.boldtext}>   Description  </Typography>     </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {groupView.data?.group_data?.description}    </Grid> 

                  <Grid item xs={12} sm={6} md={6} lg={6}> <Typography className={classes.boldtext}> Posted Date   </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}> {moment(groupView.data?.group_data?.createdAt).format("DD-MM-YYYY")}  </Grid> 
               
               
                  <Grid item xs={12}  > 
                  <MUIDataTable
            options={{
              pagination: true,
              sort: false,
              selectableRows: "none",
              search: false,
              filter: false,
              print: false,
              download: false,
              responsive: mobileQuery===true? 'vertical' : 'standard',
            
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              }
            }}
            columns={[
              {
                name: "S.No",
              },
              {
                name: "Name",
              },

              {
                name: "Email",
              },

              {
                name: "Mobile",
              }, 
              {
                name: "Posted Date",
              },
              {
                name: "Actions",
              },
            ]}
            data={groupView.data?.contact_data?.map((item, index) => {
              return [ 
                index + 1,
                item.name,
                item.email,
                item.country_code + " " +item.mobile,  
                moment(item.createdAt).format("DD-MM-YYYY"),

                <>
                  <Grid container className={classes.space}>
                    <Grid item xs className={classes.toolAlign}>
                      <Tooltip
                        title="Remove Contact"
                        placement="bottom"
                        aria-label="remove"
                      >
                        <DeleteForeverIcon
                          className={classes.toolIconDelete}
                          onClick={(e) => {
                            handleRemoveOpen();
                            setId({
                              ...Id,
                              group_id: groupView.data?.group_data?.id,
                              contact_id: item.conatct_id 
                            });
                          }}
                        />
                      </Tooltip>
                     
                      
                   
                    </Grid>
                  </Grid>
                </>,
              ];
            })}
          />
                  
                    </Grid> 
             

                </Grid>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.drawerFooter}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={toggleDrawer(anchor, false)}
                  >
                    
                    Close
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          </List>
        </Box>
      </>
    );
 
   

  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
          
          <PageTitle title="Group" />
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={classes.drawerClose}>
          <div className={classes.lgButton}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              onClick={(e) => {
                onGroupAdd.setValues({
                  "name":  "",
                  "description": "", 
                })

                setDataList("ADD"); 
                setState({ ...state, right: true });
              }}
            >
              Add New Group
            </Button>
          </div>

          <div className={classes.smButton}>
            <Button
              variant="contained"
              size="small"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              color="primary"
              onClick={(e) => {
                onGroupAdd.setValues({
                  "name":  "",
                  "description": "", 
                })

                setDataList("ADD");
              
                setState({ ...state, right: true });
              }}
            >
              
              Add
            </Button>
          </div>
        
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIDataTable
            options={{
             
              onRowSelectionChange: (currentRowsSelected, allRowsSelected) => handleRowSelectionChange(allRowsSelected),
              pagination: false,
              search: false,
              download: false,
              print: false,
              viewColumns: true,
              filter: false,
              filterType: "dropdown",
              responsive: mobileQuery===true? 'vertical' : 'standard',
              customToolbarSelect: () => <SelectElements />,
              customToolbar: () => <HeaderElements />,
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              }
            }}
            columns={[
              {
                name: "S.No",
              },
              {
                name: "Name",
              },

              {
                name: "Description",
              },
 
              {
                name: "Posted Date",
              },
              {
                name: "Actions",
              },
            ]}
            data={groupData.map((item, index) => {
              return [
               currerntPage !== 0 ? 10 * currerntPage - 10 + index + 1   : index + 1 ,
                 
                item.name, 
                item.description,    
                moment(item?.createdAt).format("DD-MM-YYYY"),

                <>
                  <Grid container className={classes.space}>
                    <Grid item xs className={classes.toolAlign}>
                      <Tooltip
                        title="Edit Group"
                        placement="bottom"
                        aria-label="edit"
                      >
                        <EditIcon
                          className={classes.toolIcon}
                          onClick={(e) => {
                           handleShow(item.id, "EDIT");
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        title="View Group"
                        placement="bottom"
                        aria-label="view"
                      >
                        <ViewIcon
                          className={classes.toolIcon}
                          onClick={(e) => {
                            handleShow(item.id, "VIEW");
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        title="Add Group Contact"
                        placement="bottom"
                        aria-label="add"
                      >
                        <GroupAddIcon
                          className={classes.toolIcon}
                          onClick={(e) => {
                              handleUploadOpen();

                            //  getViewAllContact(item.id);

                             onGroupContactAdd.setValues({
                              "contact_id":  onGroupContactAdd.values.contact_id,
                              "group_id": item.id, 
                            })

                          }}
                        />
                      </Tooltip>
                      
                    </Grid>
                  </Grid>
                </>,
              ];
            })}
          />

          <Grid container spacing={2} className={classes.pagination}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={count}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid>



<SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
            classes={{ paper: classes.drawer }}
            disableSwipeToOpen={false}
          >
  
            {  list("right")} 

          </SwipeableDrawer>


        </Grid>
      </Grid>

      


      <Dialog onClose={handleUploadClose} aria-labelledby="dialog-title" open={uploadOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>

<DialogContent style={{ padding: "0px"}} > 
<DialogContentText>  
  
 

<Grid className={classes.center +" "+classes.dialogTitle}  >
 
 <Grid
             container
             direction="row"
             spacing={2}
             
           >
                 <Grid item xs={12} style={{ justifyContent: "space-between", color:"white", display: "flex", padding:"0px 20px"}}>
             <Typography  variant="subtitle1"> Upload Contact</Typography>
 
           
               <CloseIcon
                 className={classes.closeBtn}
                 size="14px"
                 onClick={(e)=>[
                  handleUploadClose()
                 ]}
               />
             </Grid>
           </Grid>
       </Grid>

<GroupContact onGroupContactAdd={ onGroupContactAdd }    handleUploadClose={handleUploadClose} upload={"true"}   />

 

        
      </DialogContentText> 
 

</DialogContent>

</Dialog>
 <Dialog onClose={handleRemoveClose} aria-labelledby="dialog-title" open={removeOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>

           {/* <DialogTitle >Are you sure you want to remove this contact? </DialogTitle>  */}
           <DialogContent style={{ padding: "0px"}} >  
          <DialogContentText>
            
          <Grid className={classes.center +" "+classes.dialogTitle}  >
 
 <Grid container direction="row" spacing={2}   >
      <Grid item xs={12} style={{ justifyContent: "space-between", color:"white", display: "flex", padding:"0px 20px"}}>
        <Typography  variant="subtitle1"> Are you sure you want to remove this contact? </Typography>
 
               <CloseIcon   className={classes.closeBtn}  size="14px"
                 onClick={(e)=>[
                  handleRemoveClose()
                 ]}
               />
             </Grid>
           </Grid>
       </Grid>
              </DialogContentText> 
         <DialogActions> 
         <Button  variant="contained"  color="primary"  size="small"  onClick={(e) => { removeGroupContactData(Id) }}>  Yes   </Button> 
         <Button variant="contained" size="small" color="secondary" onClick={handleRemoveClose} >No</Button>

         </DialogActions>
          
          </DialogContent>

        </Dialog>
        



        <Dialog onClose={handleBulkRemoveClose} aria-labelledby="dialog-title" open={removeBulkOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>

 
        <DialogContent style={{ padding: "0px"}} >  
<DialogContentText>  
  
<Grid className={classes.center +" "+classes.dialogTitle}  >
 
 <Grid container direction="row" spacing={2}   >
      <Grid item xs={12} style={{ justifyContent: "space-between", color:"white", display: "flex", padding:"0px 20px"}}>
        <Typography  variant="subtitle1">   Are you sure you want to remove this Group?  </Typography>
 
               <CloseIcon   className={classes.closeBtn}  size="14px"
                 onClick={(e)=>[
                  handleBulkRemoveClose()
                 ]}
               />
             </Grid>
           </Grid>
       </Grid>
  </DialogContentText> 
<DialogActions> 
<Button  variant="contained"  color="primary"  size="small"  onClick={(e) => { removeGroup() }}>  Yes   </Button> 
<Button variant="contained" size="small" color="secondary" onClick={handleBulkRemoveClose} >No</Button>

</DialogActions>

</DialogContent>

</Dialog>

<Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>



    </>
  );
}

