import React, { useState, useEffect, useReducer } from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Button,
  List,
  Box,
  SwipeableDrawer,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Dialog, DialogContent,   DialogContentText, DialogActions,
  Backdrop,
  CircularProgress,
  
} from "@material-ui/core";
 import PageTitle from "../../components/PageTitle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import { toast } from "react-toastify";
 import AddCircleIcon from "@material-ui/icons/AddCircle";
 import ViewIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
 import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
 
 import useStyles from "../../themes/style.js";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Notification from "../../components/Notification/Notification";
import { contactSchema, contactWithoutSchema, groupContactwithoutSchema } from "../../components/Yup/Yup";
import { addContact, editContact, viewContact, viewAllContact, deleteContacts } from '../../services/contact.api';
import { groupList } from '../../services/group.api';
import { addGroupContact } from '../../services/groupContact.api';

import { useFormik } from 'formik';
import PhoneInput from "react-phone-input-2";

//import { isPossiblePhoneNumber } from 'libphonenumber-js';
//import parseMobile from 'libphonenumber-js/mobile'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import GroupContact from "./GroupContact";
 import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/bootstrap.css";

export default function Contact(props) {
  const classes = useStyles();
  const mobileQuery = useMediaQuery('(max-width:600px)');  

   const [contactData, setContactData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  var token = localStorage.getItem("token");


  const [contactView, setContactView] = useState({
    ContactData:[],
    ContactsGroupData:[]
   });

  // const [page, setPage] = useState(0);
  // const [currerntPage, setCurrerntPage] = useState(1);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [dataList, setDataList] = useState("ADD");

  //const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(false);
 
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress,
          className: classes.notification,
        },
    );
  }

  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');
  const [uploadOpen, setUploadOpen] = React.useState(false);
  const [removeOpen, setRemoveOpen] = React.useState(false);
  const handleUploadOpen = () => {
    setUploadOpen(true);
  };

  const handleUploadClose = () => {
    setUploadOpen(false);
  };


  const handleRemoveOpen = () => {
    setRemoveOpen(true);
  };

  const handleRemoveClose = () => {
    setRemoveOpen(false);
  };

  // const handleUploadContact = (e) => {
 
  //   var filenamearr = e.target.files[0].name.split(".");
  //   if (filenamearr[filenamearr.length - 1] === "xlsx") {
  //     const reader = new FileReader();
  //     reader.readAsArrayBuffer(e.target.files[0]);
  //     reader.onload = (e) => {
  //       const thisData = e.target.result;
  //       readXlsxFile(thisData).then((rows) => {
  //         const headings = rows[0];
  //         const contacts = rows.slice(1); // Exclude the first array which contains headings

  //         new Promise((resolve, reject) => {
  //           try {
  //             resolve(contacts.map((contactDetails) => {
  //               const contactInfo = {};
  //               headings.forEach((heading, index) => {
  //                 const detail = contactDetails[index];
  //                 contactInfo[heading] = detail;
  //               });
  //               return contactInfo;
  //             }));
  //           } catch (error) {
  //             console.error(error);
  //             reject(error);
  //           }
  //         }).then(output => {
           
  //           setLoader(true);
  //           uploadBulkContacts({data: output}).then((response) => {    
  //     if (response?.data?.status === true) {
  //       handleNotificationCall("success", response.data.message);  
  //       forceUpdate();
       
  //       setState({ ...state, right: false });
  //      } else {  
  //       handleNotificationCall("error", response.data.message);  
  //      }
     
  //      setLoader(false);
  //    })
  //    .catch(function (error) {
  //     console.log(error);
  //   })


  //         })
  //       })
  //     }
  //   }else{
  //     handleNotificationCall("error", "Only .xlsx extension is Allowed");  
       
  //   }
  // }

  // const handleChange = (values, allDetails) => {  
      
  //    var phone = values.replaceAll(/[- )(]/g,'');  
  //    setCountryCode("+"+allDetails.dialCode);
  //    setPhoneNumber(phone.replaceAll("+"+allDetails.dialCode,""));
  // };


  const handleChange = (values, allDetails) => {   
     setCountryCode(allDetails.dialCode);
     setPhoneNumber(values);
 };

 const SelectElements = () => <Grid className={classes.gap10}> <Button
 variant="contained"
 color="primary"
 size="small"
 startIcon={<AddCircleIcon />}
 onClick={() =>{
  
  setselectedId(selectId);
  handleUploadOpen();
 }}
>
 Add to Group
</Button>
<Button
 variant="contained"
 color="secondary"
 size="small"
 startIcon={<DeleteForeverIcon />}
 onClick={() =>{
  setselectedId(selectId);
  handleRemoveOpen();
 
 }}
>
Remove Contact
</Button>
</Grid>;
 
//  const [count, setCount] = useState(0);

//  const HeaderElements = () => <>Total : {count}</>;

 useEffect(() => {
  setLoader(true);

  const fetchData = async () => {
     
    groupList({token: token}).then((response) => {    
       if (response?.data?.status === true) {
         
        setGroupData(response.data.data);
       // setCount(response.data.count);
          setLoader(false);

       }
     
       setLoader(false);
     })
     .catch(function (error) {
      console.log(error);
    })

  };
  fetchData();
     
// eslint-disable-next-line react-hooks/exhaustive-deps
 }, [reducerValue]);

 
  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
       
      viewAllContact({token: token}).then((response) => {    
         if (response?.data?.status === true) {
           
          setContactData(response.data.data);
            //  setCount(response.data.count);
           
 
         }
       
         setLoader(false);
       })
       .catch(function (error) {
        console.log(error);
      })
 
    };
    fetchData();
       
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reducerValue]);
 


   const onContactAdd = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobile:''
    },
     validationSchema: phoneNumber === ""? contactSchema: contactWithoutSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
      setLoader(true);
      addContact(values, {mobile:phoneNumber, countryCode:countryCode, token: token}).then((response) => {    
      if (response?.data?.status === true) {
        handleNotificationCall("success", response.data.message);  
        forceUpdate();
        resetForm();
        setState({ ...state, right: false });
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false);
       setLoader(false);
     })
     .catch(function (error) {
      console.log(error);
    })

    },
 
  });  

  const onContactEdit = useFormik({
    initialValues: {  
      id:"",
      name: '',
      email: '',
      mobile:''
    },
     validationSchema:  phoneNumber === ""? contactSchema: contactWithoutSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
      setLoader(true);
      editContact(values,  {id: onContactEdit.values.id, mobile:onContactEdit.values.mobile, countryCode:countryCode===""? onContactEdit.values.country_code:countryCode, token: token}).then((response) => {    
        if (response?.data?.status === true) {
        handleNotificationCall("success", response.data.message);  
        forceUpdate();
        resetForm();
        setState({ ...state, right: false });
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false);
       setLoader(false);
     })
     .catch(function (error) {
      console.log(error);
    })

    },
    
  });  
 
  var selectId = [];

  const handleRowSelectionChange = (allRowsSelected) => {
    const id = allRowsSelected.map((rowIndex) => contactData[rowIndex.dataIndex].id);
    selectId = id;   
   };


const [selectedId, setselectedId] = React.useState(selectId);

  const onGroupContactAdd = useFormik({
    initialValues: {
      group_id: '',
      contact_id: "",
    },
     validationSchema: groupContactwithoutSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
      setLoader(true);
      addGroupContact({ group_id: groupData[values.group_id]?.id, contacts: selectedId, token: token}).then((response) => {    
      if (response?.data?.status === true) {
        handleNotificationCall("success", response.data.message);  
        forceUpdate();
        resetForm();
        handleUploadClose();
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false);
       setLoader(false);
     })
     .catch(function (error) {
      console.log(error);
    })

    },
  });  


  function handleShow(values, name) {
    setLoader(true);
    if (name === "EDIT") {
      setDataList("EDIT");
    } else {
      setDataList("VIEW");
    }
 
    viewContact({id: values, token: token}).then((response) => {    
      if (response?.data?.status === true) {    

        onContactEdit.setValues(response.data.contact_data);  
       
        setCountryCode(response.data?.contact_data?.country_code);
        setPhoneNumber(response.data?.contact_data?.country_code+response.data?.contact_data?.mobile);

        setContactView({
          ...contactView,
          ContactData: response.data.contact_data,
          ContactsGroupData: response.data.group_names, 
        }); 

        // viewAllContactsGroup({id: values}).then((result) => {    
        //   if (result.data.status === true) {    
 
           
        //   } else{
        //     setContactView({
        //       ...contactView,
        //       ContactData: [],
        //       ContactsGroupData: [],
             
        //     });
        //   }
        // })

 
        setState({ ...state, right: true });
        setLoader(false);
          }
        })
          .catch(function (error) {
           console.log(error);
         })
 
    
  }
 

  function removeContact(){

    deleteContacts({contacts: selectedId, token: token}).then((response) => {    
      if (response?.data?.status === true) {    
        forceUpdate();
         handleNotificationCall("success", response.data.message);  
       

         setLoader(false);
         handleRemoveClose();

          } else{
            handleNotificationCall("error", response.data.message);  
          }
        })
          .catch(function (error) {
           console.log(error);
         })
  }

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {  
    
    setState({ ...state, [anchor]: open });

  };

 

  const list = (anchor) =>
  dataList === "ADD" ? (
    <Box sx={{ width: "100%" }} role="presentation">
    <List>
      <Card className={classes.root}>
        <CardHeader>
          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.drawerHeader}
          >
            <Typography variant="subtitle1"> Add Contact</Typography>

            <Grid className={classes.drawerClose}>
              <CloseIcon
                className={classes.closeBtn}
                size="14px"
                onClick={toggleDrawer(anchor, false)}
              />
            </Grid>
          </Grid>
        </CardHeader>

       
     <form onSubmit={onContactAdd.handleSubmit}  >

    <CardContent className={classes.drawerContent}>
        <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="name">  Name    </InputLabel>

            <FormControl className={classes.margin}>
              <TextField
                classes={{ root: classes.customTextField }}
                InputProps={{ disableUnderline: true }}
                size="small"
                placeholder="Enter name"
                type="text"
                name="name" 
                value={onContactAdd.values.name}
                onChange={onContactAdd.handleChange}
                error={onContactAdd.touched.name && Boolean(onContactAdd.errors.name)}
                helperText={onContactAdd.touched.name && onContactAdd.errors.name}

              />
 
            </FormControl>
          </Grid>
         

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="email"> Email </InputLabel>

           
            <TextField
                    id="email"
                    classes={{ root: classes.customTextField }}
                    InputProps={{ disableUnderline: true }}
                    name="email"
                    margin="normal"
                    placeholder="Enter Email"
                    type="email"
                    fullWidth
                    value={onContactAdd.values.email}
                    onChange={onContactAdd.handleChange} 
                    error={onContactAdd.touched.email && Boolean(onContactAdd.errors.email)}
                    helperText={onContactAdd.touched.email && onContactAdd.errors.email}
   
                  /> 
           
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
          <InputLabel shrink htmlFor="phoneNumber"> Mobile </InputLabel> 
            

<PhoneInput
      country={"in"}
      id="mobile"
      enableSearch={true}
      value={phoneNumber}
      className={classes.customTextField}
       
    onChange={(val, allDetails) => {
      handleChange(val, allDetails);
  }}
   
   margin="normal"
   name="mobile"
   variant="outlined"
 
    />
<Typography variant="inherit" color="error">{ phoneNumber === ""?  onContactAdd.touched.mobile && onContactAdd.errors.mobile :""}</Typography>

          </Grid>

        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.drawerFooter}
        >
       

          <Button
            variant="contained"
            color="primary"
            size="small"
             type="submit"
             disabled={onContactAdd.isSubmitting}
          >
            Save
          </Button>

          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={toggleDrawer(anchor, false)}
          >
           
            Close
          </Button>
        </Grid>
      </CardActions>
    </form>
    </Card>
        </List>
        </Box>
 
  ) :  dataList === "EDIT" ? (
    <Box sx={{ width: "100%" }} role="presentation">
    <List>
      <Card className={classes.root}>
        <CardHeader>
          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.drawerHeader}
          >
            <Typography variant="subtitle1"> Edit Contact</Typography>

            <Grid className={classes.drawerClose}>
              <CloseIcon
                className={classes.closeBtn}
                size="14px"
                onClick={toggleDrawer(anchor, false)}
              />
            </Grid>
          </Grid>
        </CardHeader>

                   <form onSubmit={onContactEdit.handleSubmit}  >
                  <CardContent className={classes.drawerContent}>
                      <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                          <InputLabel shrink htmlFor="name">  Name    </InputLabel>

                          <FormControl className={classes.margin}>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              InputProps={{ disableUnderline: true }}
                              size="small"
                              placeholder="Enter name"
                              type="text"
                              name="name" 
                             
                              value={onContactEdit.values.name}
                              // onChange={onContactEdit.handleChange}
                              onChange={(e) => {
                                onContactEdit.setValues({
                                  "id":  onContactEdit.values.id,
                                  "name":e.target.value,
                                  "email":onContactEdit.values.email,
                                  "mobile": onContactEdit.values.mobile,
                                  "country_code":  onContactEdit.values.country_code
                                })
                               }}
                              error={onContactEdit.touched.name && Boolean(onContactEdit.errors.name)}
                              helperText={onContactEdit.touched.name && onContactEdit.errors.name} 
                            />


                       
                          </FormControl>
                        </Grid>
                       

                     <Grid item xs={12} sm={6} md={6} lg={6}>
                          <InputLabel shrink htmlFor="email"> Email  </InputLabel>

                          <FormControl className={classes.margin}>    
                        <TextField
                        classes={{ root: classes.customTextField }}
                        InputProps={{ disableUnderline: true }}
                        size="small"
                        placeholder="Enter email"
                        type="text"
                        name="email" 
                        value={onContactEdit.values.email}
                       // onChange={onContactEdit.handleChange}
                       onChange={(e) => {
                        onContactEdit.setValues({
                          "id":  onContactEdit.values.id,
                          "email":e.target.value,
                          "name": onContactEdit.values.name,
                          "mobile": onContactEdit.values.mobile,
                          "country_code":  onContactEdit.values.country_code
                        })
                       }}
                        error={onContactEdit.touched.name && Boolean(onContactEdit.errors.email)}
                        helperText={onContactEdit.touched.email && onContactEdit.errors.email}

                      />

                           
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6}>
          <InputLabel shrink htmlFor="phoneNumber"> Mobile </InputLabel> 
           
          {/* <MuiPhoneNumber
                     
                      value={onContactEdit.values.country_code+""+onContactEdit.values.mobile}
                      id="mobile"
                      defaultCountry={"in"}
                      className={classes.customTextField}
                      style={{ width: "100%" }}
                     
                     onChange={(val, allDetails) => {
                      handleChange(val, allDetails);
                    
                     }}

                     onBlur={(e) => {
                  
                      onContactEdit.setValues({  
                        "id":  onContactEdit.values.id,
                        "mobile": phoneNumber,
                        "email":  onContactEdit.values.email,
                        "name": onContactEdit.values.name,
                        "country_code": countryCode
                      })
                       isValidPhoneNumberWithCountryCode(phoneNumber)
                     
                     }}
                      margin="normal"
                      name="mobile"
                      variant="outlined"
                      error={ phoneNumber === ""? onContactEdit.touched.mobile && Boolean(onContactEdit.errors.mobile): valid === true? "Not a valid Mobile Number":""}
                      helperText={phoneNumber === ""?  onContactEdit.touched.mobile && onContactEdit.errors.mobile: valid === true? "Not a valid Mobile Number":"" }
                   
                    />
  */}


  
<PhoneInput
      country={"in"}
      id="mobile"
      enableSearch={true}
      value={phoneNumber}
      className={classes.customTextField}
      style={{ width: "100%" }}
      onChange={(val, allDetails) => {
        handleChange(val, allDetails);
      
       }}

       onBlur={(e) => {
    
        onContactEdit.setValues({  
          "id":  onContactEdit.values.id,
          "mobile": phoneNumber,
          "email":  onContactEdit.values.email,
          "name": onContactEdit.values.name,
          "country_code": countryCode
        })
          
       
       }}
   margin="normal"
   name="mobile"
   variant="outlined"                
    />


<Typography variant="inherit" color="error">{ phoneNumber === ""?  onContactEdit.touched.mobile && onContactEdit.errors.mobile :""}</Typography>


          </Grid>

                       
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        className={classes.drawerFooter}
                      >
               

                        <Button
                       
                           variant="contained"
                           color="primary"
                           size="small"
                            type="submit"
                            disabled={onContactEdit.isSubmitting}

                        >
                          Update  
                        </Button>

                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          onClick={toggleDrawer(anchor, false)}
                        >
                         
                          Close
                        </Button>
                      </Grid>
                    </CardActions>
                  </form>
                  </Card>
        </List>
        </Box>
  ) : (
      <>
        <Box sx={{ width: "100%" }} role="presentation">
          <List>
            <Card className={classes.root}>
              <CardHeader>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  className={classes.drawerHeader}
                >
                  <Typography variant="subtitle1"> View Contact</Typography>

                  <Grid className={classes.drawerClose}>
                    <CloseIcon
                      className={classes.closeBtn}
                      size="14px"
                      onClick={toggleDrawer(anchor, false)}
                    />
                  </Grid>
                </Grid>
              </CardHeader>

              <CardContent className={classes.drawerContent}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}>  Name  </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {contactView.ContactData?.name}  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>    <Typography className={classes.boldtext}>   Email  </Typography>     </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {contactView.ContactData?.email}    </Grid> 


                  <Grid item xs={12} sm={6} md={6} lg={6}>    <Typography className={classes.boldtext}>   Mobile  </Typography>     </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {contactView.ContactData?.country_code + " " +contactView.ContactData?.mobile}    </Grid> 
             
                  <Grid item xs={12} sm={6} md={6} lg={6}>    <Typography className={classes.boldtext}>   Group Name  </Typography>     </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {contactView?.ContactsGroupData?.map((group) => ( group?.name +", " )   )}    </Grid> 


                  <Grid item xs={12} sm={6} md={6} lg={6}> <Typography className={classes.boldtext}> Posted Date   </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}> {moment(contactView.ContactData?.createdAt).format("DD-MM-YYYY")}  </Grid> 
                </Grid>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.drawerFooter}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={toggleDrawer(anchor, false)}
                  >
                   
                    Close
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          </List>
        </Box>
      </>
    );
 

    


  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
         
          <PageTitle title="Contact" />
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={classes.drawerClose}>
          <div className={classes.lgButton}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              onClick={(e) => {
                setDataList("ADD");
                setCountryCode("");
                setPhoneNumber(""); 

                onContactAdd.setValues({
                  "name": '',
                  "email": '',
                  "mobile":''
                })


                setState({ ...state, right: true });
              }}
            >
              Add New Contact
            </Button>
          </div>

          <div className={classes.smButton}>
            <Button
              variant="contained"
              size="small"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              color="primary"
              onClick={(e) => {
                setDataList("ADD");
                setCountryCode("");
                setPhoneNumber("");
                onContactAdd.setValues({
                  "name": '',
                  "email": '',
                  "mobile":''
                })
                setState({ ...state, right: true });
              }}
            >
             
              Add
            </Button>
          </div>
          <SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
            classes={{ paper: classes.drawer }}
            disableSwipeToOpen={false}
          >
 
            {   list("right")  }
          </SwipeableDrawer>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIDataTable
            options={{
             
              onRowSelectionChange: (currentRowsSelected, allRowsSelected) => handleRowSelectionChange(allRowsSelected),

              search: false,
              download: false,
              print: false,
              viewColumns: true,
              filter: false,
              filterType: "dropdown",
              responsive: mobileQuery===true? 'vertical' : 'standard',
              customToolbarSelect: () => <SelectElements />,
          //  customToolbar: () => <HeaderElements />,
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              }
            }}
            columns={[
              {
                name: "S.No",
              },
              {
                name: "Name",
              },

              {
                name: "Email",
              },

              {
                name: "Mobile",
              },
              {
                name: "Posted Date",
              },
              {
                name: "Actions",
              },
            ]}
            data={contactData.map((item, index) => {
               return [
                //currerntPage !== 0 ? 10 * currerntPage - 10 + index + 1   : index + 1 ,
                index + 1,
                item.name,
                item.email,
                item.country_code + " " +item.mobile,  
                moment(item.createdAt).format("DD-MM-YYYY"),

                <>
                  <Grid container className={classes.space}>
                    <Grid item xs className={classes.toolAlign}>
                      <Tooltip
                        title="Edit Contact"
                        placement="bottom"
                        aria-label="edit"
                      >
                        <EditIcon
                          className={classes.toolIcon}
                          onClick={(e) => {
                           handleShow(item.id, "EDIT");
                           
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        title="View Contact"
                        placement="bottom"
                        aria-label="view"
                      >
                        <ViewIcon
                          className={classes.toolIcon}
                          onClick={(e) => {
                            handleShow(item.id, "VIEW");
                          }}
                        />
                      </Tooltip>
                     
                   
                    </Grid>
                  </Grid>
                </>,
              ];
            })}
          />

          {/* <Grid container spacing={2} className={classes.pagination}>
            <TablePagination
              rowsPerPageOptions={[50]}
              component="div"
              count={count}
              rowsPerPage={50}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid> */}
        </Grid>
      </Grid>

 <Dialog onClose={handleUploadClose} aria-labelledby="dialog-title" open={uploadOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>
 <DialogContent style={{ padding: "0px"}} > 
<DialogContentText>  
   
<Grid className={classes.center +" "+classes.dialogTitle}  >
 
 <Grid container direction="row" spacing={2}   >
      <Grid item xs={12} style={{ justifyContent: "space-between", color:"white", display: "flex", padding:"0px 20px"}}>
        <Typography  variant="subtitle1"> Add Contact to Group</Typography>
 
               <CloseIcon   className={classes.closeBtn}  size="14px"
                 onClick={(e)=>[
                  handleUploadClose()
                 ]}
               />
             </Grid>
           </Grid>
       </Grid>

<GroupContact onGroupContactAdd={ onGroupContactAdd }   groupData={groupData}  handleUploadClose={handleUploadClose} upload={"false"}     />

{/* <Grid className={classes.center}> 
<Button variant="contained" size="small" color="secondary" onClick={handleUploadClose} >Close</Button>

</Grid> */}
 
</DialogContentText> 
</DialogContent>
</Dialog>


<Dialog onClose={handleRemoveClose} aria-labelledby="dialog-title" open={removeOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>
 <DialogContent style={{ padding: "0px"}} > 
<DialogContentText>  
   
<Grid className={classes.center +" "+classes.dialogTitle}  >
 
 <Grid container direction="row" spacing={2}   >
      <Grid item xs={12} style={{ justifyContent: "space-between", color:"white", display: "flex", padding:"0px 20px"}}>
        <Typography  variant="subtitle1"> Are you sure, want to delete selected contact?</Typography>
 
               <CloseIcon   className={classes.closeBtn}  size="14px"
                 onClick={(e)=>[
                  handleRemoveClose()
                 ]}
               />
             </Grid>
           </Grid>
       </Grid>

  
 
</DialogContentText> 
<DialogActions> 
<Button  variant="contained"  color="primary"  size="small"  onClick={(e) => {    
  removeContact();
  }}>  Yes   </Button> 
<Button variant="contained" size="small" color="secondary" onClick={handleRemoveClose} >No</Button>

</DialogActions>
</DialogContent>
</Dialog>



      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}