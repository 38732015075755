import React, { useState, useEffect, useReducer, useRef } from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Button, 
   Backdrop,
  CircularProgress, 
  TextField,
  TablePagination
} from "@material-ui/core";
 import PageTitle from "../../components/PageTitle"; 
 import moment from "moment";
import useStyles from "./style.js";
import useGlobalStyles from "../../themes/style";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {  viewAllTags } from '../../services/tags.api'; 
import {  getContactsByTag } from '../../services/contact.api'; 

import { Autocomplete } from "@material-ui/lab"; 

  import "react-toastify/dist/ReactToastify.css";


export default function SearchTag(props) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
   const mobileQuery = useMediaQuery('(max-width:600px)');   
   const [searchData, setSearchData] = useState([]); 
  

  const [page, setPage] = useState(0);
   const [currerntPage, setCurrerntPage] = useState(1);
  const [reducerValue, forceUpdate ] = useReducer((x) => x + 1, 0);
  const filterRef = useRef(null); 
  const token =localStorage.getItem("token");  

 const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(false);
 
  const HeaderElements = () => <>Total : {count}</>;
 
  const handleChangePage = (event, newPage) => {
    setLoader(true);
    setPage(newPage);
    setCurrerntPage(newPage + 1);
    const form = filterRef.current;
    
    getContactsByTag({
      page: newPage + 1, 
      referredBy: `${form["referral"].value}`,
      fromDate: `${form["fromDate"].value}`,
      toDate: `${form["toDate"].value}`,
      token: token 
    }).then((response) => {    
      if (response.data.status === true) { 
        
        setSearchData(response.data.data);
           setCount(response.data.count);
           setLoader(false);

      }  else{
        setCount(0);
        setSearchData([]);
       setLoader(false);
     }
    }) 
    .catch(function (error) {
     console.log(error);
   })

  };

  const [tagData, setTagData] = React.useState([]); 
  const [tagValue, setTagValue] = React.useState(null);
 
  function getFilterData() {
    setLoader(true);
     
   // const form = filterRef.current;
 

    getContactsByTag({
        page: 1, 
        tag_id: tagValue?.id, 
        token: token 
      }).then((response) => {    
        if (response.data.status === true) { 
       
          setLoader(false);
          setSearchData(response.data.data);
         
            }  else{
              setCount(0);
              setSearchData([]);
             setLoader(false);
           }
             
           
          }) 
            .catch(function (error) {
             console.log(error);
           })
           
  }
 
const resetForm = (e) => {
    filterRef.current.reset(); 
    forceUpdate();
  };

 
  
  useEffect(() => {

    
    viewAllTags({token:token}).then((response) => {    
    
    if(response.data.status === true) {  
     setTagData(response.data.data);
     setLoader(false);
    } 
     
   }) 
   .catch(function (error) {
    console.log(error);
  })

   // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ ])

//   useEffect(() => {
//     setLoader(true);

//     const fetchData = async () => {
       
//         getContactsByTag({  page: 1, token: token }).then((response) => {    
//          if (response.data.status === true) { 
//           setCount(response.data.count);
//            setSearchData(response.data.data);
//            setLoader(false);  
//          } else {
//           setCount(0);
//           setSearchData([]);
//          setLoader(false);
//          }
        
        
//        }) 
//        .catch(function (error) {
//         console.log(error);
//       })
 
//     };
//     fetchData();
       
//  // eslint-disable-next-line react-hooks/exhaustive-deps
//    }, [reducerValue]);
  
 
  

 
  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
          
          <PageTitle title="Search" />
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={classes.drawerClose}>
          
        </Grid>
      </Grid>


      <form
        ref={filterRef}
        onSubmit={(e) => {
          e.preventDefault();
          getFilterData();
        }}
      >
        <Grid container spacing={2} className={globalClasses.filterGap}>
         
        

        <Autocomplete  underlineShow={false}  options={tagData} 
                                getOptionLabel={(option) =>
                                  option.name !==undefined? `${ option.name } ` : ''
                                }

                               

                                  value={ tagValue }
                                  onChange={(e, value) => {  
                                  setTagValue(value); 
                                  }
                                } 
                                renderInput={(params) => (
                                  <TextField 
                                    {...params}
                                    name="tag_name" 
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }} 
                                    type="text"
                                    
                                  />
                                )}
                              />

          <div className={globalClasses.buttons}>
            <Button
              variant="contained"
              size="small"
              color="primary" 
              type="submit"
            >
              Search
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => resetForm()}
            >
              Reset
            </Button>
          </div>
        </Grid>
      </form>

      

      <Grid container spacing={2}>
        <Grid item xs={12}>
        <MUIDataTable
            title={""}
            options={{
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              },
              pagination: false,
              sort: false,
              selectableRows: "none",
              search: false,
              filter: false,
              print: false,
              download: false,
           customToolbar: () => <HeaderElements />,
              responsive: mobileQuery===true? 'vertical' : 'standard',
            }}
            columns={[
              {
                name: "S.No",
              },
              {
                name: "Contact",
              },
             
              {
                name: "Tags",
              },
             
              {
                name: "Date",
              },
            ]}
            data={searchData.map((item, index) => {
              return [
                currerntPage !== 0 ? 10 * currerntPage - 10 + index + 1   : index + 1 ,  
                
                item.name !== null ? item.name: item.phone_number,
                <Button variant="contained" size="small" style={{ pointerEvents: "none"}} className={ classes.green  }> 
                  {item?.tag_name}  </Button>,
          
                
                moment(item.created).format("DD-MM-YYYY"),
              ];
            })}
          />

          <Grid container spacing={2} className={globalClasses.pagination}>
            <TablePagination
              rowsPerPageOptions={[50]}
              component="div"
              count={count}
              rowsPerPage={50}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      </Grid>


    

      

      <Backdrop className={globalClasses.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

